export const config = {
	"mainnet": {
		"derps": {
			"abi": require("./abis/derps.json"),
			"address": "0x8500d84b203775FC8B418148223872b35c43B050"
		},
		"derp": {
			"abi": require("./abis/derp.json"),
			"address": "0xe920d1DA9A4D59126dC35996Ea242d60EFca1304"
		},
		"derper": {
			"abi": require("./abis/derper.json"),
			"address": "0x0333902eABC3B6e090EFB5de5e317E572433174f"
		},
		"treasury": {
			"address": "0x68705637c16bF32A28a82914f7aADEFB3aBE31CD"
		},
		"derpPromoItems": {
			"abi": require("./abis/promoItems.json"),
			"address": "0xc7B2C6CF2Ed40e4514B48a71e3aa0D56a2b14427"
		}

	},
	"testnet": {
		"derps": {
			"abi": require("./abis/derps.json"),
			"address": "0xf7DDC89c07C5e1E8cE8BA3821321543045e19e9c"
		},
		"derp": {
			"abi": require("./abis/derp.json"),
			"address": "0x5fc63611E508879dDD5bdde0782Df0f2E543B172"
		},
		"derper": {
			"abi": require("./abis/derper.json"),
			"address": "0xFc8109734C93543ae601D8876D1280fa6e2704C4"
			//0x80d70BA931a3708aBb4e54492b18300Cf5F32E72
		},
		"treasury": {
			"address": "0xe02237ac085146389e8aCA2E501394bBa75A58e8"
		},
		"derpPromoItems": {
			"abi": require("./abis/promoItems.json"),
			"address": "0x33dF939c84AE702Fd25968B4BD98b943982DA8D4"
		}
	}
}


export const shop = {
	"event1": {
		"101": {
			"name": "Derpmander",
			"img": require("./imgs/derpmanderTRANS.PNG"),
			"cost": 69
		}, 
		"102": {
			"name": "Derptle",
			"img": require("./imgs/derptleTRANS.PNG"),
			"cost": 69	
		},
		"103": {
			"name": "Peekuhdewd",
			"img": require("./imgs/peekuhdewdTRANS.PNG"),
			"cost": 69	
		},
		"104": {
			"name": "Da Misssooor",
			"img": require("./imgs/misssooorTRANS.PNG"),
			"cost": 420	
		},
		"105": {
			"name": "Da Button",
			"img": require("./imgs/button.PNG")	
		}
	}
	}
