import { ConnectButton } from '@rainbow-me/rainbowkit'
import bg from './imgs/back.gif'
import fg from './imgs/land.jpg'
import mintGif from './imgs/mint.gif'
import dd from './imgs/DERP_TOKEN.png'
import React from 'react'
import Web3 from 'web3'

import {
	useAccount,
	useChainId,
  usePrepareTransactionRequest,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from 'wagmi'

import { parseEther } from 'viem'
import { config, shop } from './config'

import pendingGif from './imgs/pending2.gif'
import flashing from './imgs/flashing.gif'
import derpeItems from './imgs/derp-items.png'

import discord from './imgs/discord.png'
import tg from './imgs/icon--telegram.png'
import x from './imgs/icon--twitter.png'

import closeImg from './imgs/close.png'

import drink from './imgs/drink.PNG'
import itemImg from './imgs/cardpack.png'

const getTransactionReceipt = (hash, callback= ()=>{}) => {
    console.log(hash)
    window.ethereum.request({
      method: "eth_getTransactionReceipt",
      params: [hash]
    }).then((res)=>{
      if (res == null) {
        getTransactionReceipt(hash, callback)
      }
      callback(res)
    }).catch((err)=>{
      console.log(err)
    })
  }

const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.soniclabs.com'));



export function Web3App (props) {
	const [cost, setCost] = React.useState(100)  
	const [pending, setIsPending] = React.useState(false)
	const [pendingText, setPendingText] = React.useState("Transaction is in process") 
	const { address, isConnected } = useAccount()
	const chainId  = useChainId()

	const [networkConfig, setNetworkConfig] = React.useState(config["mainnet"])

	

	const sendRequest = (contract, data, callback = ()=>{}, payable = 0, gasAmount=0) => {
		setIsPending(true)
	

		if (typeof window.ethereum !== 'object') { 
		
		} else {
		

		window.ethereum.request({
			method: "eth_sendTransaction",
			params: [
			  {
			    from: address,
			    to: contract,
			    value: payable,
			    data: data,
			  },
			],}).then((hash)=>{
			  getTransactionReceipt(hash,(receipt)=>{
			    if (receipt != null) {
			      setIsPending(false)
			      callback()
			    }
			  })
			}).catch((err)=>{
			  setIsPending(false)
			})	
		}
		
	}

	const estimateGas = (contract, data, callback = ()=>{}) => {



	if (typeof window.ethereum !== 'object') {
		alert("Please open this page in the Metamask browser")
	
	} else {

		window.ethereum.request(
	        {
	          method: "eth_estimateGas",
	          params: [
	            {
	              from: address,
	              to: contract,     
	              value: web3.utils.toHex(0),
	              data: data,
	            },
	          ],
	        }
	      ).then((gasAmount)=>{
	      	console.log(gasAmount)
	        callback(gasAmount)
	      }).catch((err)=>{
	        alert(err.data.message)
	      })
	      
		}
   
}
	

	const derps = new web3.eth.Contract(networkConfig["derps"]["abi"], networkConfig["derps"]["address"])
	const derper = new web3.eth.Contract(networkConfig["derper"]["abi"], networkConfig["derper"]["address"])
	const derpToken = new web3.eth.Contract(networkConfig["derp"]["abi"], networkConfig["derp"]["address"])
	React.useEffect(()=>{
		

	},[networkConfig])
  

  const [style, setStyle] = React.useState({backgroundImage: `url(${fg})`})

  React.useEffect(
  	() => {
  		if (isConnected && !pending) {
  			setStyle({backgroundImage: `url(${fg})`})
  		} else {
  			if (pending) {
  				setStyle({backgroundImage: `url(${flashing})`})
  			} else {
  				if (isConnected) {
  					setStyle({backgroundImage: `url(${fg})`})
  				} else {
  					setStyle({backgroundImage: `url(${fg})`})
  				}
  				
  			}
  		}
  	},
  	[pending, isConnected]
  	)

  const [bal, setBal] = React.useState(0)
  const getBal = () => {
  	derps.methods.balanceOf(address)
  		.call({from: address})
  		.then((balance)=>{
  			setBal(balance)
  		})
  }


const getDerpBal = (callback = ()=>{}) => {
	derpToken.methods.balanceOf(address)
		.call({from: address})
		.then((b)=>{
			callback(b)
		})
}




const isLive = (callback = ()=>{}) => {
	derper.methods.isLive()
		.call({from: address})
		.then((live)=>{
			callback(live)
		})
}

const checkClaim = (callback = ()=>{}) => {
	derper.methods.checkClaim()
		.call({from: address})
		.then((claimAmount)=>{
			callback(Number(web3.utils.fromWei(claimAmount,"ether")))
		})
}

const checkBalanceOfDerper = (callback = ()=>{}) => {
	derpToken.methods.balanceOf(networkConfig["derper"]["address"])
		.call({from: address})
		.then((b)=>{
			callback(b)
			setEmitterBalance(b)
		})
}

const claimDerp = (callback = ()=>{}) => {
	let data = derper.methods.claimDerp().encodeABI()
	estimateGas(
		networkConfig["derper"]["address"],
		data,
		()=>{
			sendRequest(
			networkConfig["derper"]["address"],
			data,
			callback
			)
		})
	
}
const changeClaim = () => {
	console.log("go")
	let data = derper.methods.changeAmountPerClaim(2).encodeABI()
	sendRequest(networkConfig["derper"]["address"],data,()=>{})
}

  const [buttonState, setButtonState] = React.useState("")
const [pressCount, setPressCount] = React.useState(0)
  const press = () => {

    console.log(pressCount)
    setPressCount(pressCount + 1)

    //check isLive
    	// require true
    
	    //checkClaim
	    	// ensure response > 0
	    	//check balanceOf Derper
	    		// ensure balance > claim
	    		// claimDerp
	    		
	isLive((live)=>{
		if (live) {
			checkClaim((claimAmount)=>{
				if (claimAmount > 0) {
					checkBalanceOfDerper((balance)=>{
						console.log(web3.utils.fromWei(balance,"ether"),claimAmount)
						if (Number(web3.utils.fromWei(balance,"ether")) >= Number(claimAmount)) {
							claimDerp(()=>{
								//alert("Success")
							})
						} else {
							alert("Emitter does not have enough $DERP")
						}
					})
				} else {
					alert("Please wait 60 seconds before claiming again. If you didn't just claim, this message indicates you do not have an available claim. Please ensure you are using the correct wallet.")
				}
			})
		} else {
			alert("Claim is not live")
		}
	})


  }

 const [emitterBalance, setEmitterBalance] = React.useState(0)
 

  return (
  	<div className={"App App--" + isConnected + " App-chain--" + chainId}>
	  	<div className="bg" style={{backgroundImage: `url(${bg})`}}>
	       	<div className="action-grid">
	       		<div className="grid-item grid-item--main">
	       			<h2>WARNING!</h2>
	       			<div className="grid-item-inner">
	       				<div className="button">
				          {
				            //<h2>{seconds}</h2>
				          }
				          <button className={"btn btn--" + buttonState} onClick={press}>DERP</button>
				        </div>
				        <p>Do NOT press the button.</p>
				        
	       			</div>
	       		</div>
	       		<div className="grid-item-row">
	       			<a href="https://snacks.derpe.xyz" target="_blank" className="grid-item grid-item--small">
	       				<div className="grid-item-inner">
	       					<img src={drink} />
	       					<h3>Snacks</h3>
	       				</div>
	       			</a>
	       			<a href="https://shop.derpe.xyz" target="_blank" className="grid-item grid-item--small">
	       				<div className="grid-item-inner">
	       					<img src={itemImg} />
	       					<h3>Items</h3>
	       				</div>
	       			</a>
	       		</div>
	       	</div>
 	
	        
	        
	        <div className="dd"><img src={dd}/></div>
	  		

	  		 <div className="links">
	          <a href="https://my.derpe.xyz" target="_blank">View</a>
	           
	          <a href="https://discord.gg/3BUen4F9Tw" target="_blank"><img src={discord} /></a>
	          
	          <a href="https://t.me/derpedewdz" target="_blank"><img src={tg}/></a>
	          
	          <a href="https://x.com/derpedewdz" target="_blank"><img src={x}/></a>
	          
	        </div>

	        <div className="connect-button">
	  			<div className="inliner docs"><a href="https://docs.derpe.xyz" target="_blank">DOCS</a></div>
	  			<div className="inliner"><ConnectButton /></div>
	  		</div>

	        <div className={"pending pending--" + pending}>
	  			<p className="pending-title">Transaction Pending</p>
	  			<p className="pending-text">{pendingText}</p>
	  			<div className="pending-gif"><img src={pendingGif} /></div>
	  		</div>
         
      </div>

	  	
	 
  	</div>
  	)
};